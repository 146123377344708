<template>
  <tr>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900 uppercase"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      {{ venta.canal }}
    </td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      <span v-if="venta.canal !== ''">{{ venta.sem0 | currency }}</span>
      {{ venta.canal === 'total' || venta.canal === '' ? '' : venta.sem0percent }}
    </td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      <span v-if="venta.canal !== ''">{{ venta.sem1 | currency }}</span>
      {{ venta.canal === 'total' || venta.canal === '' ? '' : venta.sem1percent }}
    </td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      <span v-if="venta.canal !== ''">{{ venta.sem2 | currency }}</span>
      {{ venta.canal === 'total' || venta.canal === '' ? '' : venta.sem2percent }}
    </td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      <span v-if="venta.canal !== ''">{{ venta.sem3 | currency }}</span>
      {{ venta.canal === 'total' || venta.canal === '' ? '' : venta.sem3percent }}
    </td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"
        :class="(venta.canal === 'total' || venta.canal === 'propias' || venta.canal === 'plataformas')?'font-semibold': 'font-normal'">
      <span v-if="venta.canal !== ''">{{ venta.sem4 | currency }}</span>
      {{ venta.canal === 'total' || venta.canal === '' ? '' : venta.sem4percent }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ItemVentaCanalGrupo',
  props: {
    venta: Object
  }
}
</script>
