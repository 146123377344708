<template>
  <div>
    <div class="flex flex-col" v-if="ventas.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Canal
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{
                  periodos.periodo0 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Margen
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{
                  periodos.periodo1 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Margen {{ periodos.periodo1 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{
                  periodos.periodo2 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Margen {{ periodos.periodo2 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{
                  periodos.periodo3 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Margen {{ periodos.periodo3 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{
                  periodos.periodo4 }}
                </th>
                <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Margen {{ periodos.periodo4 }}
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
              <ItemVentaMargen v-for="(venta, key) in ventas" :venta="venta" :key="key"
                              class="bg-white odd:bg-gray-100"/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemVentaMargen from './ItemVentaMargen'

export default {
  name: 'ListVentasMargen',
  props: {
    ventas: Array,
    periodos: Object
  },
  components: {
    ItemVentaMargen
  }
}
</script>
