<template>
  <tr>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900 uppercase" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.canal }}</td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-red-700" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.sem0 | currency }} {{ cancelacion.canal === 'total' ? '' : cancelacion.sem0percent }}</td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-red-700" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.sem1 | currency }} {{ cancelacion.canal === 'total' ? '' : cancelacion.sem1percent }}</td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-red-700" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.sem2 | currency }} {{ cancelacion.canal === 'total' ? '' : cancelacion.sem2percent }}</td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-red-700" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.sem3 | currency }} {{ cancelacion.canal === 'total' ? '' : cancelacion.sem3percent }}</td>
    <td class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-red-700" :class="(cancelacion.canal === 'total')?'font-semibold': 'font-normal'">{{ cancelacion.sem4 | currency }} {{ cancelacion.canal === 'total' ? '' : cancelacion.sem4percent }}</td>
    </tr>
</template>

<script>
export default {
  name: 'ItemCancelacionesCanal',
  props: {
    cancelacion: Object
  }
}
</script>
