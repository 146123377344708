<template>
  <div class="container mx-auto">
    <div class="flex flex-row mb-6">
      <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a Acumulado</router-link>
    </div>
    <div class="flex flex-row mb-1">
      <h3 class="block text-lg uppercase leading-5 font-semibold text-blue-800">Canal</h3>
    </div>
    <div class="grid grid-cols-1 gap-6">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createBono">
          <div class="grid grid-cols-12 gap-6 mb-6">
            <div class="col-span-8">
              <label class="block text-sm leading-5 text-gray-700">Seleccionar Fecha Inicial</label>
              <datepicker v-model="fechaConsultaInicial" :language="es" :format="format" @closed="obtenerVentas"></datepicker>
            </div>
            <div class="col-span-8">
              <label class="block text-sm leading-5 text-gray-700">Seleccionar Fecha Final</label>
              <datepicker v-model="fechaConsultaFinal" :language="es" :format="format" @closed="obtenerVentas"></datepicker>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mb-6" v-show="consulta">
            <div class="col-span-8">
            <label class="block text-sm leading-5 font-medium text-gray-700 mr-4">Consulta Mensual</label>
            <input type="checkbox" class="form-checkbox" v-model="comparativo" @change="obtenerVentas">
            <label for="checkbox" class="ml-4 text-sm leading-5 text-gray-700">Mes / Año</label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="flex items-center justify-center mb-6">
      <label for="fecha" class="block text-sm leading-5 font-medium text-gray-700 mr-4">{{ fechaDiaInicial | moment("DD MMMM YYYY") }} - {{ fechaConsultaFinal | moment("DD MMMM YYYY") }}</label>
    </div>
    <div class="flex items-center mb-1">
      <label for="grupo" class="block text-sm leading-5 font-medium text-gray-700 mr-4 mb-1 pl-2 uppercase">Grupo</label>
    </div>
    <ListVentasCanalGrupo :ventas="ventas" :periodos="periodos" class="mb-6"/>
    <div class="flex items-center mb-1">
      <label for="grupo" class="block text-sm leading-5 font-medium text-gray-700 mr-4 mb-1 pl-2 uppercase">Sociedades</label>
    </div>
    <ListVentasCanalGrupo :ventas="ventasSociedad" :periodos="periodos" class="mb-6"/>
    <div class="flex items-center mb-6">
      <label for="canal" class="block text-sm leading-5 font-medium text-gray-700 mr-4">Canal</label>
      <div class="relative mr-4">
        <select v-model="canalSeleccionado" @change="cambiarCanal" class="block uppercase appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-sm py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option v-for="(item, key) in canales" :value="item.clave" :key="key">{{item.nombre}}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <ListVentas :ventas="ventasCanal" :periodos="periodos" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import { periodos } from '@/utils/periodos'
import ListVentasCanalGrupo from '@/components/ListVentasCanalGrupo'
import ListVentas from '@/components/ListVentas'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
export default {
  name: 'Canal',
  components: {
    Datepicker,
    ListVentasCanalGrupo,
    ListVentas
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaDiaInicial: moment().tz('America/Belize').format('YYYY-MM-DD'),
      fechaDiaFinal: moment().tz('America/Belize').format('YYYY-MM-DD'),
      fechaConsultaInicial: this.$store.getters.getFechaInicial,
      fechaConsultaFinal: this.$store.getters.getFechaFinal,
      ventas: [],
      ventasSociedad: [],
      ventasCanal: [],
      periodos: periodos(),
      comparativo: this.$store.getters.getComparativo,
      consulta: this.$store.getters.getConsulta,
      canalSeleccionado: this.$store.getters.getCanal,
      canales: []
    }
  },
  methods: {
    obtenerVentas () {
      let fechaInicial = moment().tz('America/Belize').format('YYYY-MM-DD')
      let fechaFinal = moment().tz('America/Belize').format('YYYY-MM-DD')

      if (moment(this.fechaConsultaInicial).isValid()) {
        this.$store.dispatch('FECHA_INICIAL', this.fechaConsultaInicial)
        this.$store.dispatch('FECHA_FINAL', this.fechaConsultaFinal)
        this.$store.dispatch('COMPARATIVO', this.comparativo)

        this.fechaDiaInicial = moment(this.fechaConsultaInicial).tz('America/Belize').format('YYYY-MM-DD')
        this.fechaDiaFinal = moment(this.fechaConsultaFinal).tz('America/Belize').format('YYYY-MM-DD')

        fechaInicial = moment(this.fechaConsultaInicial).tz('America/Belize').format('YYYY-MM-DD')
        fechaFinal = moment(this.fechaConsultaFinal).tz('America/Belize').format('YYYY-MM-DD')

        // Consulta / Periodos
        const fechaInicioMes = moment(fechaInicial).startOf('month').format('YYYY-MM-DD')
        const fechaFinMes = moment(fechaInicial).endOf('month').format('YYYY-MM-DD')
        this.periodos = periodos(fechaInicial, fechaFinal, this.comparativo)

        if (fechaInicioMes === fechaInicial && fechaFinMes === fechaFinal) {
          this.consulta = true
          this.$store.dispatch('CONSULTA', true)
        } else {
          this.consulta = false
          this.comparativo = false
          this.$store.dispatch('CONSULTA', false)
          this.$store.dispatch('COMPARATIVO', false)
        }
      }

      this.$http.post('/api/ventas/canalgrupo', { fechaInicial: fechaInicial, fechaFinal: fechaFinal, comparativo: this.comparativo }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => (this.ventas = data))

      this.$http.post('/api/ventas/canalsociedad', { fechaInicial: fechaInicial, fechaFinal: fechaFinal, comparativo: this.comparativo }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => (this.ventasSociedad = data))

      this.$http.post('/api/ventas/canaltipo', { fechaInicial: fechaInicial, fechaFinal: fechaFinal, comparativo: this.comparativo, canal: this.canalSeleccionado }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.ventasCanal = data))
    },
    cambiarCanal () {
      this.$store.dispatch('CANAL_SELECCIONADO', this.canalSeleccionado)
      this.obtenerVentas()
    },
    listaCanales () {
      this.$http.post('/api/ventas/canales', {}, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.canales = data
            this.$store.dispatch('CANAL_SELECCIONADO', data[0].clave)
            this.canalSeleccionado = data[0].clave
            this.obtenerVentas()
          }
        })
    }
  },
  mounted () {
    this.listaCanales()
  }
}
</script>
