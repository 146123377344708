<template>
  <tr>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <span v-if="cancelacion.nombre === 'TOTAL'">{{ cancelacion.nombre }}</span>
      <span v-if="cancelacion.nombre !== '' && cancelacion.nombre !== 'TOTAL'"><router-link :to="{ name: 'canal-sucursal-cancelaciones', params: { id: cancelacion.sucursal } }">{{ cancelacion.nombre }}</router-link></span>
    </td>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <p class="text-red-700">{{ cancelacion.canc | currency }} ({{ parseFloat(isNaN((cancelacion.canc / cancelacion.importe)) ? 0 : (cancelacion.canc / cancelacion.importe) * 100).toFixed(2) }}%)</p>
      <p class="text-gray-600">{{ cancelacion.importe | currency }}</p>
    </td>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <p class="text-red-700">{{ cancelacion.cancb | currency }} ({{ parseFloat(isNaN((cancelacion.cancb / cancelacion.importeb)) ? 0 : (cancelacion.cancb / cancelacion.importeb) * 100).toFixed(2) }}%)</p>
      <p class="text-gray-600">{{ cancelacion.importeb | currency }}</p>
    </td>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <p class="text-red-700">{{ cancelacion.cancc | currency }} ({{ parseFloat(isNaN((cancelacion.cancc / cancelacion.importec)) ? 0 : (cancelacion.cancc / cancelacion.importec) * 100).toFixed(2) }}%)</p>
      <p class="text-gray-600">{{ cancelacion.importec | currency }}</p>
    </td>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <p class="text-red-700">{{ cancelacion.cancd | currency }} ({{ parseFloat(isNaN((cancelacion.cancd / cancelacion.imported)) ? 0 : (cancelacion.cancd / cancelacion.imported) * 100).toFixed(2) }}%)</p>
      <p class="text-gray-600">{{ cancelacion.imported | currency }}</p>
    </td>
    <td v-if="cancelacion.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal" :class="(cancelacion.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <p class="text-red-700">{{ cancelacion.cance | currency }} ({{ parseFloat(isNaN((cancelacion.cance / cancelacion.importee)) ? 0 : (cancelacion.cance / cancelacion.importee) * 100).toFixed(2) }}%)</p>
      <p class="text-gray-600">{{ cancelacion.importee | currency }}</p>
    </td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="cancelacion.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
  </tr>
</template>

<script>
export default {
  name: 'ItemCancelaciones',
  props: {
    cancelacion: Object
  }
}
</script>
