import Vue from 'vue'
import Vuex from 'vuex'
import authClient from '@/utils/auth'
import moment from 'moment-timezone'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: authClient.getToken() || '',
    fechaInicial: moment().tz('America/Belize').format(),
    fechaFinal: moment().tz('America/Belize').format(),
    consulta: false,
    comparativo: false,
    sucursal: 0,
    canal: 'vta_comedor'
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getFechaInicial: state => state.fechaInicial,
    getFechaFinal: state => state.fechaFinal,
    getConsulta: state => state.consulta,
    getComparativo: state => state.comparativo,
    getSucursal: state => state.sucursal,
    getCanal: state => state.canal
  },
  mutations: {
    AUTH_LOGIN (state, token) {
      state.token = token
    },
    AUTH_LOGOUT (state) {
      state.token = ''
    },
    FECHA_INICIAL (state, fecha) {
      state.fechaInicial = fecha
    },
    FECHA_FINAL (state, fecha) {
      state.fechaFinal = fecha
    },
    CONSULTA (state, value) {
      state.consulta = value
    },
    COMPARATIVO (state, value) {
      state.comparativo = value
    },
    SUCURSAL_SELECCIONADA (state, value) {
      state.sucursal = value
    },
    CANAL_SELECCIONADO (state, value) {
      state.canal = value
    }
  },
  actions: {
    AUTH_LOGIN ({ commit }, user) {
      return new Promise((resolve, reject) => {
        authClient.login(user)
          .then((response) => {
            const token = response
            if (!token) {
              return resolve(token)
            }
            authClient.setToken(token)
            commit('AUTH_LOGIN', token)
            resolve(!!token)
          })
          .catch(err => {
            authClient.logout()
            reject(err)
          })
      })
    },
    AUTH_LOGOUT ({ commit }) {
      authClient.logout()
      commit('AUTH_LOGOUT')
    },
    FECHA_INICIAL ({ commit }, fecha) {
      commit('FECHA_INICIAL', fecha)
    },
    FECHA_FINAL ({ commit }, fecha) {
      commit('FECHA_FINAL', fecha)
    },
    CONSULTA ({ commit }, value) {
      commit('CONSULTA', value)
    },
    COMPARATIVO ({ commit }, value) {
      commit('COMPARATIVO', value)
    },
    SUCURSAL_SELECCIONADA ({ commit }, value) {
      commit('SUCURSAL_SELECCIONADA', value)
    },
    CANAL_SELECCIONADO ({ commit }, value) {
      commit('CANAL_SELECCIONADO', value)
    }
  },
  modules: {
  }
})
