<template>
  <div class="container mx-auto">
    <div class="flex flex-row mb-2 justify-end">
      <router-link to="/" class="bg-green-700 hover:bg-green-800 text-white text-xs py-2 px-12 ml-3 rounded focus:outline-none focus:shadow-outline">Ventas</router-link>
    </div>
    <div class="flex flex-row mb-6 justify-end">
      <span class="text-lg  font-medium text-red-700 mr-auto uppercase">Cancelaciones</span>
      <router-link to="/canal-cancelaciones" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline">Canal</router-link>
    </div>
    <div class="grid grid-cols-1 gap-6">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createBono">
          <div class="grid grid-cols-12 gap-6 mb-6">
            <div class="col-span-8">
              <label class="block text-sm leading-5 text-gray-700">Seleccionar Fecha Inicial</label>
              <datepicker v-model="fechaConsultaInicial" :language="es" :format="format" @closed="obtenerVentas"></datepicker>
            </div>
            <div class="col-span-8">
              <label class="block text-sm leading-5 text-gray-700">Seleccionar Fecha Final</label>
              <datepicker v-model="fechaConsultaFinal" :language="es" :format="format" @closed="obtenerVentas"></datepicker>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mb-6" v-show="consulta">
            <div class="col-span-8">
            <label class="block text-sm leading-5 font-medium text-gray-700 mr-4">Consulta Mensual</label>
            <input type="checkbox" class="form-checkbox" v-model="comparativo" @change="obtenerVentas">
            <label for="checkbox" class="ml-4 text-sm leading-5 text-gray-700">Mes / Año</label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="flex items-center justify-center mb-6">
      <label for="fecha" class="block text-sm leading-5 font-medium text-gray-700 mr-4">{{ fechaDiaInicial | moment("DD MMMM YYYY") }} - {{ fechaConsultaFinal | moment("DD MMMM YYYY") }}</label>
    </div>
    <ListCancelaciones :cancelaciones="cancelaciones" :periodos="periodos" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import { periodos } from '@/utils/periodos'
import ListCancelaciones from '@/components/ListCancelaciones'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
export default {
  name: 'Cancelaciones',
  components: {
    Datepicker,
    ListCancelaciones
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaDiaInicial: moment().tz('America/Belize').format('YYYY-MM-DD'),
      fechaDiaFinal: moment().tz('America/Belize').format('YYYY-MM-DD'),
      fechaConsultaInicial: this.$store.getters.getFechaInicial,
      fechaConsultaFinal: this.$store.getters.getFechaFinal,
      cancelaciones: [],
      periodos: periodos(),
      comparativo: this.$store.getters.getComparativo,
      consulta: this.$store.getters.getConsulta
    }
  },
  methods: {
    obtenerVentas () {
      let fechaInicial = moment().tz('America/Belize').format('YYYY-MM-DD')
      let fechaFinal = moment().tz('America/Belize').format('YYYY-MM-DD')

      if (moment(this.fechaConsultaInicial).isValid()) {
        this.$store.dispatch('FECHA_INICIAL', this.fechaConsultaInicial)
        this.$store.dispatch('FECHA_FINAL', this.fechaConsultaFinal)
        this.$store.dispatch('COMPARATIVO', this.comparativo)

        this.fechaDiaInicial = moment(this.fechaConsultaInicial).tz('America/Belize').format('YYYY-MM-DD')
        this.fechaDiaFinal = moment(this.fechaConsultaFinal).tz('America/Belize').format('YYYY-MM-DD')

        fechaInicial = moment(this.fechaConsultaInicial).tz('America/Belize').format('YYYY-MM-DD')
        fechaFinal = moment(this.fechaConsultaFinal).tz('America/Belize').format('YYYY-MM-DD')

        // Consulta / Periodos
        const fechaInicioMes = moment(fechaInicial).startOf('month').format('YYYY-MM-DD')
        const fechaFinMes = moment(fechaInicial).endOf('month').format('YYYY-MM-DD')
        this.periodos = periodos(fechaInicial, fechaFinal, this.comparativo)

        if (fechaInicioMes === fechaInicial && fechaFinMes === fechaFinal) {
          this.consulta = true
          this.$store.dispatch('CONSULTA', true)
        } else {
          this.consulta = false
          this.comparativo = false
          this.$store.dispatch('CONSULTA', false)
          this.$store.dispatch('COMPARATIVO', false)
        }
      }

      this.$http.post('/api/cancelaciones/full', { fechaInicial: fechaInicial, fechaFinal: fechaFinal, tipo: 2, comparativo: this.comparativo }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.cancelaciones = data))
    }
  },
  mounted () {
    this.obtenerVentas()
  }
}
</script>
