import Vue from 'vue'
import VueRouter from 'vue-router'
import authClient from '@/utils/auth'
import Login from '@/views/Login'
import Acumulado from '@/views/Acumulado'
import Canal from '@/views/Canal'
import CanalCancelaciones from '@/views/CanalCancelaciones'
import CanalSucursal from '@/views/CanalSucursal'
import CanalSucursalCancelaciones from '@/views/CanalSucursalCancelaciones'
import Cancelaciones from '@/views/Cancelaciones'
import Margen from '@/views/Margen'
import Proyeccion from '@/views/Proyeccion'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'acumulado',
    component: Acumulado,
    meta: { requiresAuth: true }
  },
  {
    path: '/canal-sucursal',
    name: 'canal-sucursal',
    component: CanalSucursal,
    meta: { requiresAuth: true }
  },
  {
    path: '/canal-sucursal-cancelaciones',
    name: 'canal-sucursal-cancelaciones',
    component: CanalSucursalCancelaciones,
    meta: { requiresAuth: true }
  },
  {
    path: '/canal',
    name: 'canal',
    component: Canal,
    meta: { requiresAuth: true }
  },
  {
    path: '/canal-cancelaciones',
    name: 'canal-cancelaciones',
    component: CanalCancelaciones,
    meta: { requiresAuth: true }
  },
  {
    path: '/cancelaciones',
    name: 'cancelaciones',
    component: Cancelaciones,
    meta: { requiresAuth: true }
  },
  {
    path: '/margen',
    name: 'margen',
    component: Margen,
    meta: { requiresAuth: true }
  },
  {
    path: '/proyeccion',
    name: 'proyeccion',
    component: Proyeccion,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authClient.isAuthenticated()) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
