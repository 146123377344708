const moment = require('moment-timezone')

const fechaDefault = moment().tz('America/Belize').format('YYYY-MM-DD')

exports.periodos = (fechaInicial = fechaDefault, fechaFinal = fechaDefault, comparativo = false) => {
  const periodo = {
    periodo0: 'Importe',
    periodo1: 'Ant7',
    periodo2: 'Ant14',
    periodo3: 'Ant21',
    periodo4: 'Ant28'
  }

  // Consulta Mensual
  const fechaInicioMes = moment(fechaInicial).startOf('month').format('YYYY-MM-DD')
  const fechaFinMes = moment(fechaInicial).endOf('month').format('YYYY-MM-DD')

  // Consulta Anual
  const fechaInicioAno = moment(fechaInicial).startOf('year').format('YYYY-MM-DD')
  const fechaFinAno = moment(fechaInicial).endOf('year').format('YYYY-MM-DD')

  if (fechaInicioMes === fechaInicial && fechaFinMes === fechaFinal) {
    if (comparativo === false) {
      periodo.periodo0 = moment(fechaInicioMes).format('MMM')
      periodo.periodo1 = moment(fechaInicioMes).subtract(1, 'month').format('MMM')
      periodo.periodo2 = moment(fechaInicioMes).subtract(2, 'month').format('MMM')
      periodo.periodo3 = moment(fechaInicioMes).subtract(3, 'month').format('MMM')
      periodo.periodo4 = moment(fechaInicioMes).subtract(4, 'month').format('MMM')
    } else {
      periodo.periodo0 = moment(fechaInicioMes).format('MMM YYYY')
      periodo.periodo1 = moment(fechaInicioMes).subtract(1, 'year').format('MMM YYYY')
      periodo.periodo2 = moment(fechaInicioMes).subtract(2, 'year').format('MMM YYYY')
      periodo.periodo3 = moment(fechaInicioMes).subtract(3, 'year').format('MMM YYYY')
      periodo.periodo4 = moment(fechaInicioMes).subtract(4, 'year').format('MMM YYYY')
    }
  } else if (fechaInicioAno === fechaInicial && fechaFinAno === fechaFinal) {
    periodo.periodo0 = moment(fechaInicioAno).format('YYYY')
    periodo.periodo1 = moment(fechaInicioAno).subtract(1, 'year').format('YYYY')
    periodo.periodo2 = moment(fechaInicioAno).subtract(2, 'year').format('YYYY')
    periodo.periodo3 = moment(fechaInicioAno).subtract(3, 'year').format('YYYY')
    periodo.periodo4 = moment(fechaInicioAno).subtract(4, 'year').format('YYYY')
  } else {
    periodo.periodo0 = 'Importe'
    periodo.periodo1 = 'Ant7'
    periodo.periodo2 = 'Ant14'
    periodo.periodo3 = 'Ant21'
    periodo.periodo4 = 'Ant28'
  }

  return periodo
}
